import React, {useState, useContext} from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {GlobalStateContext, GlobalDispatchContext} from '../context/GlobalContextProvider'

class IndexPost extends React.Component {
  render() {
    const { data, type } = this.props
    return (
      <React.Fragment>
        <div className="row product-main">
          {data.data.allMarkdownRemark.nodes.map(item => (
            item.frontmatter.type===type &&
            <div
              className="Catalogue__item col-sm-12 col-md-6 col-lg-4"
              key={item.id}
            >
              <Link to={item.frontmatter.slug} key={item.id}>
                <div className="details_List" key={`abc-${item.id}`}>
                  {item.frontmatter.featuredImage ? (
                   <Img
                   fluid={{
                     src: item.frontmatter.featuredImage,
                     aspectRatio: 1.77,
                     sizes: "",
                     srcSet: "",
                   }}
                 />   
                  ) : (
                    <div className="no-image"> No Image </div>
                  )}
                  <div className="details_inner">
                    <h2>{item.frontmatter.name}</h2>
                    <div className="row">
                      <div className="col-sm-4 align-self-center">
                  
                        <span className="price">${item.frontmatter.price}</span>
                      </div>
                      <div className="col-sm-8 text-right align-self-center">
                        <button

                          className="Product snipcart-add-item"
                          data-item-id={item.frontmatter.slug}
                          data-item-price={item.frontmatter.price}
                          // data-item-image={item.frontmatter.featuredImage}
                          data-item-name={item.frontmatter.name}
                          data-item-url="/"
                        >
                          <i className="fas fa-shopping-bag" />
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

const MatHangTabs= ({data})=>{

  const dispatch = useContext(GlobalDispatchContext);
  const globalState=useContext(GlobalStateContext);
  return (
    <Tabs selectedIndex={globalState.tabIndex} onSelect={index=> dispatch({type:'TABINDEX_CHANGE', payload:index})}>
    <TabList>
      <Tab>Kate 1m2</Tab>
      <Tab>Kate 1m2 Cao Cấp</Tab>
      <Tab>Kate 1m6</Tab>
      <Tab>Kate 1m6 Cao Cấp</Tab>
      <Tab>Tuyết Mưa</Tab>
      <Tab>Tăm (Lỳ)</Tab>
      <Tab>Tuyết Nhung</Tab>
      <Tab>Tuyết Thun</Tab>
      <Tab>Tuyết Mịn</Tab>
      <Tab>Carolane (Chéo)</Tab>
      <Tab>Lụa</Tab>
      <Tab>Kaky</Tab>
      <Tab>Tol Gân</Tab>
      <Tab>Me Ngựa</Tab>
      <Tab>Fo</Tab>
      <Tab>Siêu Cờ</Tab>
      <Tab>Sẹc</Tab>
    </TabList>
  
    <TabPanel>
     <IndexPost data={data} type='kate-12' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='kate-12-cc' />
    </TabPanel>
  
    <TabPanel>
     <IndexPost data={data} type='kate-16' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='kate-16-cc' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='tuyetmua' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='tuyettam' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='tuyetnhung' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='tuyetthun' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='tuyetmin' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='carolan' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='lua' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='kaky' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='gan' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='mengua' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='fo' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='sieuco' />
    </TabPanel>
  
    <TabPanel>
    <IndexPost data={data} type='sec' />
    </TabPanel>
  </Tabs>
  )
  
}
const IndexPage = data => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`vai gia xuong`, `Vải gía xưởng`, `Tân Tiến Thành`, `vải Tiến Sương`]} />
      <div className="container">
        {/* <div className="text-center mt-5">
          <h2 className="with-underline">Mặt Hàng</h2>
        </div> */}
        <MatHangTabs data={data}/>
        {/* <IndexPost data={data}></IndexPost> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark {
      nodes {
        frontmatter {
          date
          featuredImage
          name
          price
          slug
          title
          type
        }
      }
    }
  }
`
export default IndexPage